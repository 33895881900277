class User {

    constructor() {
        this.init()
    }

    init() {
        this.name = localStorage.getItem('userName')
        this.email = localStorage.getItem('userEmail')
        this.loggedIn = localStorage.getItem('authenticated')
    }

    /**
     *
     * @param data object
     * @param data.name string
     * @param data.email string
     * @param callback function
     */
    authenticated(data, callback) {
        //console.log(data)
        localStorage.setItem('userName', data.content.user_name)
        localStorage.setItem('userEmail', data.content.user_email)
        localStorage.setItem('Bearer', data.content.access_token)
        localStorage.setItem('authenticated', true)

        this.init()

        callback()
    }

    logout(callback) {

        localStorage.setItem('userName', '')
        localStorage.setItem('userEmail', '')
        localStorage.setItem('Bearer', '')
        localStorage.setItem('authenticated', '')

        this.init()

        callback()
    }

    /**
     *
     * @return {boolean}
     */
    isLoggedIn() {
        return Boolean(this.loggedIn) === true
    }
}

export default new User()