
import React from "react"

export default function ButtonRoundedFullBasePrimaryBasic(props) {
    return (
        <>
            {/*<!-- Component: Base primary basic button --> */}
            <button type={props.type} className="inline-flex mx-auto items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded-md whitespace-nowrap bg-indigo-500 hover:bg-indigo-600 focus:bg-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-indigo-300 disabled:bg-indigo-300 disabled:shadow-none">
                <span className="">Se connecter</span>
            </button>
            {/*<!-- End Base primary basic button --> */}
        </>
    )
}
