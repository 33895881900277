import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import user from "../../Models/user";

export const ProtectedRoute = () => {
    const auth = null; // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return user.isLoggedIn() ? <Outlet /> : <Navigate to="/login" />;
}
