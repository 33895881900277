
import React from 'react';
import './App.css';

import { Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Account from './components/Account'
import Home from './components/Home';
import NavBar from './components/NavBar';

import PostIndex from './components/Posts/PostIndex';
import PostCreate from './components/Posts/PostCreate';
import PostEdit from './components/Posts/PostEdit';
import { PostProvider } from './components/Contexts/PostContext'
import { ProtectedRoute } from './components/ProtectedRoutes/ProtectedRoutes';
import Dashboard from './components/Dashboard/Dashboard';


function App() {

  return (
    <React.Fragment>

      <PostProvider>
        <div className='bg-slate-100 min-h-screen'>

          <NavBar />

          <Routes>
            <Route path={'/'} element={<Home />} />
            <Route path={'/login'} element={<Login />} />
            <Route path={'/account'} element={<Account />} />
            <Route path={'/posts'} element={<PostIndex />} />
            <Route path={'/posts/create'} element={<PostCreate />} />
            <Route path={'/posts/:id/edit'} element={<PostEdit />} />
            <Route exact path='/dashboard' element={<ProtectedRoute />}>
              <Route exact path='/dashboard' element={<Dashboard />} />
            </Route>

            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>


        </div>
      </PostProvider>

    </React.Fragment>
  );
}



export default App;
