import { useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../api/Network';

const PostContext = createContext();

export const PostProvider = ({ children }) => {

    const initialFormValues = {
        name: "",
        slug: ""
    }

    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState([]);
    const [errors, setErrors] = useState({});
    const [formValues, setFormValues] = useState(initialFormValues);
    const navigate = useNavigate();

    const getPosts = async () => {
        const apiPosts = await API.get('/api/v1/posts');
        setPosts(apiPosts.data.data);
    };

    const getPost = async (id) => {
        const response = await API.get('/api/v1/posts/' + id);
        const apiPost = response.data.data;
        setPost(apiPost);
        setFormValues({
            name: apiPost.name,
            slug: apiPost.slug
        })
    };

    const storePost = async (e) => {
        e.preventDefault();
        try {
            await API.post('/api/v1/posts', formValues);
            setFormValues(initialFormValues);
            setErrors({});
            navigate('/posts');

        } catch (err) {
            if (err.response.status == 422) {
                setErrors(err.response.data.errors);
            }
        }
    };

    const updatePost = async (e) => {
        e.preventDefault();
        try {
            await API.put('/api/v1/posts/' + post.id, formValues);
            setErrors({});
            setFormValues(initialFormValues)
            navigate('/posts');
        } catch (err) {
            if (err.response.status == 422) {
                setErrors(err.response.data.errors)
            }
        }
    }

    const deletePost = async (id) => {
        if (!window.confirm('Are you sure ?')) {
            return;
        }
        await API.delete('/api/v1/posts/' + id);
        getPosts();
    }



    const onChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
    }

    return <PostContext.Provider value={{ post, posts, getPost, getPosts, onChange, formValues, storePost, errors, updatePost, deletePost }}>{children}</PostContext.Provider>
}

export default PostContext;