import React from "react";
import { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import user from "../../Models/user";
import PostContext from "../Contexts/PostContext";

import '../../App.css';


function PostIndex() {
    const { posts, getPosts, deletePost } = useContext(PostContext)

    useEffect(() => {

        getPosts();
    }, []);
    return (
        <div className="mt-10 container mx-auto px-3 md:px-0">
            <div className="flex justify-between mb-6">
                <h1 className='text-start font-bold text-2xl'>Liste des posts</h1>

                {user.isLoggedIn() ? <Link to="/posts/create" className="px-4 py-2 bg-violet-400 hover:bg-violet-600 text-white rounded-md">Nouveau post</Link> : ''}
            </div>

            <div className="relative overflow-x-auto mt-6">

                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Id
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Slug
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {posts.map((post) => {
                            return (
                                <tr key={post.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {post.id}
                                    </th>
                                    <td className="px-6 py-4">
                                        {post.name}
                                    </td>
                                    <td className="px-6 py-4">
                                        {post.slug}
                                    </td>

                                    <td className="px-6 py-4">
                                        {user.isLoggedIn() ?
                                            <div class="flex">
                                                <Link to={`/posts/${post.id}/edit`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                    </svg>
                                                </Link>
                                                <button onClick={() => deletePost(post.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                </button>
                                            </div> : ''}

                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div >
    );

};

export default PostIndex;