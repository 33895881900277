import React from "react";
import user from "../../Models/user";
// import { Container, Grid, Paper, Typography } from "@mui/material";

function Dashboard() {
    return (
        <div className="container mx-auto mt-10 px-3 md:px-0">

            <h1 className="font-bold text-2xl">Hello {user.name}, you're logged in!</h1>

        </div>
    )
}

export default Dashboard