import React from "react";
import { useContext } from "react";
import PostContext from "../Contexts/PostContext";

import '../../App.css';

function PostCreate() {
    const { onChange, formValues, storePost, errors } = useContext(PostContext)

    return (
        <div>
            <div className="container mx-auto mt-10 px-3 md:px-0">
                <h1 className='text-start font-bold text-2xl'>Ajouter un post</h1>
                <div className="bg-white p-5 rounded-md mt-6">

                    <form onSubmit={storePost} className="w-full m-auto">
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            <div>
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                <input type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required name="name" value={formValues['name']} onChange={onChange} />
                                {errors.name && <span className="text-xs text-red-600">{errors.name[0]}</span>}
                            </div>

                            <div>
                                <label htmlFor="slug" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Slug</label>
                                <input type="text" id="slug" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="john-slug" name="slug" value={formValues['slug']} onChange={onChange} />
                                {errors.slug && <span className="text-xs text-red-600">{errors.slug[0]}</span>}
                            </div>

                        </div>

                        <button type="submit" className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-blue-800">Add</button>
                    </form>

                </div>
            </div>
        </div>
    );

};

export default PostCreate;